// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
require("channels")
require('jquery')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require('css/application.scss')

//import CodeMirror from 'codemirror';
import CodeMirror from 'codemirror/lib/codemirror.js';
require('codemirror/lib/codemirror.css');
require('codemirror/theme/darcula.css');
import 'codemirror/keymap/sublime.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/ruby/ruby.js';
import 'codemirror/mode/htmlembedded/htmlembedded.js';
import 'codemirror/addon/edit/closebrackets.js';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/sass/sass.js';
import 'codemirror/addon/edit/closebrackets.js';
import 'codemirror/addon/edit/closetag.js';
import 'codemirror/addon/edit/matchbrackets.js';
import 'codemirror/addon/edit/matchtags.js';
import 'codemirror/addon/runmode/colorize.js';


$(document).ready(function (){

  $('.code-mirror-editor').each(function() {
    var textArea = document.getElementById($(this).attr("id"));

    var myCodeMirror = CodeMirror.fromTextArea(textArea, {
      mode:  textArea.dataset.mode,
      keyMap: "sublime",
      theme: "darcula",
      tabSize: 2,
      lineNumbers: true,
      lineWrapping: true,
      autoCloseBrackets: true,
      matchBrackets: true,
      matchTags: true,
      autoCloseTags: true,
      onChange: function(){editor.save()}
    });


    textArea._codeMirrorInstance = myCodeMirror;
  });

  $('.collapse-after-load').each(function() {
    $(this).addClass('collapsed');
  });

  if ($('pre.colorize-code').length > 0) {
    CodeMirror.colorize();
  }


  if ($('#settings_form').length)  {
    $('.checkin-devices').on('click', '.remove-checkin-device', function() {
      console.log("Removing device fields");
      if ($('.checkin-device-fields').length > 1) {
        $(this).closest('.checkin-device-fields').remove();
      }
    });

    $('.add-checkin-device-fields').click(function() {
      console.log("Adding new device fields");
      var new_device = $('.checkin-device-fields:first').clone();
      new_device.insertAfter('.checkin-device-fields:last');
      new_device.find('.device-name-field').val('');
      new_device.find('.device-token-field').val('');
      return false;
    });

    $('.custody-devices').on('click', '.remove-custody-device', function() {
      console.log("Removing device fields");
      if ($('.custody-device-fields').length > 1) {
        $(this).closest('.custody-device-fields').remove();
      }
    });

    $('.add-custody-device-fields').click(function() {
      console.log("Adding new device fields");
      var new_device = $('.custody-device-fields:first').clone();
      new_device.insertAfter('.custody-device-fields:last');
      new_device.find('.device-name-field').val('');
      new_device.find('.device-token-field').val('');
      return false;
    });

    $('#validate_json').click(function() {
      var content = "";
      var codeMirrorInstance = document.getElementById('setting_content')._codeMirrorInstance;
      if (codeMirrorInstance) {
        content = codeMirrorInstance.getValue();
      } else {
        alert('CodeMirror not detected');
        return false;
      }
      try {
        JSON.parse(content);
        alert('Valid JSON');
      } catch (e) {
        alert('Invalid JSON: ' + e);
      }
      return false;
    });

    $('#generate_random_code').click(function() {
      // Generate a random 16-character string made of numbers and lowercase letters
      var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      var random_code = '';
      for (var i = 0; i < 16; i++) {
        var random_index = Math.floor(Math.random() * characters.length);
        random_code += characters[random_index];
      }

      // i want to pass the reandom code into a CodeMirror instance at the current cursor position
      var codeMirrorInstance = document.getElementById('setting_content')._codeMirrorInstance;
      if (codeMirrorInstance) {
        var cursor = codeMirrorInstance.getCursor();
        codeMirrorInstance.replaceRange(random_code, cursor, cursor);
      } else {
        alert('CodeMirror not detected');
        return false;
      }
      // focus back into the Code Editor after inserting the random code
      codeMirrorInstance.focus();

      return false;
    });
  }

});