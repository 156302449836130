import consumer from "./consumer"
import $ from 'jquery';

$(document).ready(function() {

  if ($('.receive-custody-updates').length > 0) {
    console.log("Found custody updates recipient")

    consumer.subscriptions.create("CustodyChannel", {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Custody channel connected")
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        console.log('Custody update: ', data);

        var custodyUpdateEvent = new CustomEvent('custodyupdate', { 'detail': data });
        document.dispatchEvent(custodyUpdateEvent);
      }
    });
  } else {
    console.log("No custody updates recipient found")
  }
});