import consumer from "./consumer"
import $ from 'jquery';

$(document).ready(function() {

  if ($('.receive-checkin-updates').length > 0) {
    console.log("Found checkin updates recipient")

    consumer.subscriptions.create("CheckinChannel", {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Checkin channel connected")
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        console.log('Checkin update: ', data);
        $('.list .item#participant_' + data.toString()).remove();
      }
    });
  } else {
    console.log("No checkin updates recipient found")
  }
});