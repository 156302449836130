// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { createConsumer } from "@rails/actioncable"


// Check if the meta tag with name "checkin-cable-auth-token" exists
var authTokenMetaTagCheckin = document.querySelector('meta[name="checkin-cable-auth-token"]');

// Check if the meta tag with name "checkin-cable-auth-token" exists
var authTokenMetaTagCustody = document.querySelector('meta[name="custody-cable-auth-token"]');

var consumer = null

if (authTokenMetaTagCheckin) {
  // The meta tag exists
  var authToken = authTokenMetaTagCheckin.getAttribute('content');
  console.log('Checkin auth token found:', authToken);
  consumer = createConsumer(`/cable?checkin_token=${encodeURIComponent(authToken)}`)
}
else if (authTokenMetaTagCustody) {
  // The meta tag exists
  var authToken = authTokenMetaTagCustody.getAttribute('content');
  console.log('Custody auth token found:', authToken);
  consumer = createConsumer(`/cable?custody_token=${encodeURIComponent(authToken)}`)
}
else {
  consumer = createConsumer(`/cable`)
}

export default consumer;
